module.exports = {
  umbrellaExample: [
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130741349109485738/umbrella-2022-12-25-00-46-39-utc.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "599",
      _id: "1",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130741473307000852/blue-umbrella-2021-08-26-22-26-32-utc.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "2",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130741631415488602/classic-umbrella-2022-10-24-22-21-46-utc.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "3",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130741823388782652/classic-umbrella-2023-06-08-18-30-13-utc.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "4",
    },
  ],
};
