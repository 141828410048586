module.exports = {
  glassExample: [
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130728997542711307/Drinkware_01.png",
      hoverImg:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1131165959860781126/Drinkware3-3.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "599",
      _id: "1",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130728997853085817/Drinkware_02.png",
      hoverImg:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1131165959273578506/Drinkware1-1.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "2",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130728998163451954/Drinkware_03.png",
      hoverImg:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1131165960158597140/Drinkware4-4.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "3",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130728998415126658/Drinkware_04.png",
      hoverImg:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1131165959596560436/Drinkware2-2.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "4",
    },
  ],
};
